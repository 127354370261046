<template>
  <div class="relative">

    <div class="flex flex-row justify-between px-9 py-1 text-xl">
      <div class="flex flex-row pr-4 text-xl">
        <p class="">
          <strong class="pr-2">{{ $t('Welcome') }}</strong>
          <span class="text-lg">{{ activeUserInfo.organisation_name }}</span>
          <span class="text-lg">(<small>{{ activeUserInfo.customer_id }}</small>)</span>
        </p>
      </div>
      <div class="flex flex-row text-lg">
        <i18n/>
      </div>
    </div>
    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar"/>

        <!--        <h3 class="hidden md:block">Welcome <strong>{{ activeUserInfo.organisation_name }}</strong></h3>-->

        <template v-if="hasCreditAndBalances">
          <div class="p-2"></div>
          <div class="flex flex-col sm:flex-row">
            <div class="pr-3 border-0 md:border-r border-solid border-grey-light"
                 :class="[
                 {
                   'text-warning': activeUserInfo.template != 5,
                   'text-black': activeUserInfo.template == 5
                 }
               ]"
                 style="text-align: end; white-space: nowrap;">
              <p><span>{{ $t('Credit') }}</span> <strong
                class="pl-3">{{ activeUserInfo.credit | germanNumberFormat }}</strong></p>
              <!--            <small style="font-size: 10px;">{{$t('Credit')}}</small>-->
            </div>
            <div v-if="activeUserInfo.template == 5"
                 class="pl-3 pr-3 border-0 md:border-r border-solid border-grey-light text-black"
                 style="text-align: end; white-space: nowrap;">
              <p><span>{{ $t('Balance') }}</span> <strong
                class="pl-3">{{ activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat }}</strong></p>
              <!--            <small style="font-size: 10px;">{{$t('Balance')}}</small>-->
            </div>
            <div v-if="activeUserInfo.template != 5"
                 class="pl-3 pr-3 border-0 md:border-r border-solid border-grey-light"
                 :class="[activeUserInfo.balance[activeUserInfo.balance_type] < 0 ? 'text-danger' : 'text-success']"
                 style="text-align: end; white-space: nowrap;">
              <p><span>{{ $t('Balance') }}</span> <strong
                class="pl-3">{{ activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat }}</strong></p>
              <!--            <small style="font-size: 10px;">{{$t('Balance')}}</small>-->
            </div>
          </div>

          <div class="flex flex-col sm:flex-row"
          >
            <div class="pl-3 pr-3 border-0 md:border-r border-solid border-grey-light today-sale-data"

                 :class="[
                   {
                     'text-warning': activeUserInfo.template != 5,
                     'text-black': activeUserInfo.template == 5
                   }
                 ]"
                 v-if="activeUserInfo.account_role >= 2">
              <p><span>{{ $t('Sales') }}</span> <strong
                class="pl-3">{{ activeUserInfo.today_balance | germanNumberFormat }}</strong></p>
              <!--            <small style="font-size: 10px;">{{$t('Balance')}}</small>-->
            </div>
            <router-link tag="div" class="cursor-pointer" to="/seller/sales"
                         :class="[
                           {
                             'text-primary': activeUserInfo.template != 5,
                             'text-black': activeUserInfo.template == 5
                           }
                         ]"
                         v-if="activeUserInfo.account_role >= 2">

              <div class="pl-3 pr-3">
                <p><span>{{ $t('SalesCount') }}</span> <strong class="pl-3">{{
                    activeUserInfo.today_sale_count
                  }}</strong>
                </p>
                <!--            <small style="font-size: 10px;">{{$t('Balance')}}</small>-->
              </div>
            </router-link>
          </div>
        </template>
        <vs-spacer/>
        <div class="hidden md:block">
          <i18n/>
        </div>
        <div class="p-2"></div>

        <profile-drop-down/>

      </vs-navbar>
    </div>
  </div>
</template>


<script>
import ProfileDropDown from './components/ProfileDropDown.vue'

import I18n from './components/I18n.vue'

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  components: {
    ProfileDropDown,
    I18n,
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      if (this.verticalNavMenuWidth) return 'navbar-full'
    },

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    hasCreditAndBalances() {
      const role = this.activeUserInfo.account_role
      switch (role) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
  },
}
</script>

