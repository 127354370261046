<template>
  <div class="layout--main"
       :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <vx-tour :steps="steps"
             v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')"/>

    <the-customizer
      v-if="!disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"/>
    <!--    <div class=""-->
    <!--         :key="componentKey">-->
    <v-nav-menu
      class="no-print"
      :navMenuItems="navItems"
      title="PJ Telesoft"
      parent=".layout--main"/>
    <!--    </div>-->


    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay"/>

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          class="no-print"
          :navbarType="navbarType"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"/>

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <!--      <h-nav-menu-->
        <!--        :class="[-->
        <!--          {'text-white' : isNavbarDark  && !isThemeDark},-->
        <!--          {'text-base'  : !isNavbarDark && isThemeDark}-->
        <!--        ]"-->
        <!--        :navMenuItems="navMenuItems" />-->
      </template>

      <template v-else>
        <the-navbar-vertical
          class="no-print"
          :navbarColor="navbarColor"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"/>
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                   class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb"
                               :route="$route"
                               :isRTL="$vs.rtl"/>

                <!-- DROPDOWN -->
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather"/>

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})"
                           class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2"
                                      svgClasses="w-4 h-4"/>
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})"
                           class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2"
                                      svgClasses="w-4 h-4"/>
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})"
                           class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2"
                                      svgClasses="w-4 h-4"/>
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                           visibleoffset="500"
                           v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up"
                           class="shadow-lg btn-back-to-top"/>
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"
                             @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer :home-data="homeData" style="position: static !important;">
        <template slot="data">
          <div class="flex flex-col">

            <div
              v-if="activeUserInfo != null && activeUserInfo.customer_id != '500000' && activeUserInfo.internal_role != 1"
              class="flex justify-center items-center">

              <div class="flex items-end leading-none mr-1 pb-2"
                   v-if="activeUserInfo.parent_organisation_name">
                <p style=" font-weight: normal;">{{ activeUserInfo.parent_organisation_name }}
                  - </p>
              </div>
              <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.bank_name">
                <p style=" font-weight: normal;"><strong>Bank </strong> {{
                    activeUserInfo.bank_name
                  }}</p>
              </div>
              <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.iban">
                <p style=" font-weight: normal;"><strong>IBAN </strong> {{ activeUserInfo.iban }}
                </p>
              </div>
              <div class="flex items-end leading-none mr-4 pb-2" v-if="activeUserInfo.bic">
                <p style=" font-weight: normal;"><strong>BIC </strong> {{ activeUserInfo.bic }}</p>
              </div>
            </div>
            <div v-if="homeData != null && homeData.contact_details" class="flex mt-2">
              <div class="flex items-end leading-none mr-4">
                <p style=" font-weight: bold;">Support</p>
              </div>
              <template v-if="activeUserInfo.template != 0">
                <div class="flex items-end leading-none mr-4">
                  <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4"/>
                  <a style="" :href="'tel:' + homeData.contact_details.phone"><span
                    class="mt-1">{{ homeData.contact_details.phone }}</span></a>
                </div>
                <div class="flex items-end leading-none" v-if="homeData.contact_details.whatsapp">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>
                  <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
                  <a style=""
                     :href="'https://wa.me/' + homeData.contact_details.whatsapp.replace(' ', '')"
                     target="_blank">
                    <span class="mt-1">{{ homeData.contact_details.whatsapp }}</span>
                  </a>
                </div>
              </template>
              <div class="flex flex-row items-center justify-center" v-if="activeUserInfo.template == 0">
                <div class='pr-4'></div>
                <div class="flex flex-row items-center justify-center mr-4">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>
                  /
                  <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="ml-4"/>
                </div>
                <div class="flex flex-row items-end leading-none mr-4" v-for="(item, index) in getOtherContacts()" :key="index">
                  <div class="flex flex-row items-end leading-none" v-for="(item2, index2) in item">
                    <div class='pr-2' v-if="index2 === 1"></div>
                    <span v-html="item2"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="homeData != null" slot="orgname">
          <span>{{ homeData.organisation_name }}</span>
        </template>
      </the-footer>
    </div>

  </div>
</template>


<script>
import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'

import * as adminNav from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import * as sellerNav from '@/layouts/components/vertical-nav-menu/sellerNavMenuItems.js'
import * as resellerNav from '@/layouts/components/vertical-nav-menu/resellerNavMenuItems.js'

import TheCustomizer from '@/layouts/components/customizer/TheCustomizer.vue'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import HoverCallComponent from "@/views/components/HoverCallComponent";

const VxTour = () => import('@/components/VxTour.vue')

export default {
  components: {
    HoverCallComponent,
    BackToTop,
    HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour,
  },
  data() {
    return {
      navItems: [],
      componentKey: 0,
      homeData: null,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
      steps: [
        {
          target: '#btnVNavMenuMinToggler',
          content: 'Toggle Collapse Sidebar.',
        },
        {
          target: '.vx-navbar__starred-pages',
          content: 'Create your own bookmarks. You can also re-arrange them using drag & drop.',
        },
        {
          target: '.i18n-locale',
          content: 'You can change language from here.',
        },
        {
          target: '.navbar-fuzzy-search',
          content: 'Try fuzzy search to visit pages in flash.',
        },
        {
          target: '.customizer-btn',
          content: 'Customize template based on your preference',
          params: {
            placement: 'left',
          },
        },
        {
          target: '.vs-button.buy-now',
          content: 'Buy this awesomeness at affordable price!',
          params: {
            placement: 'top',
          },
        },
      ],
    }
  },
  watch: {
    activeUserInfo(newValue, oldValue) {
      this.updateUser()
    },
    '$store.state.AppActiveUser': function () {
      this.updateUser()
    },
    '$route': function () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType': function (val) {
      this.setNavMenuVisibility(val)
      this.disableThemeTour = true
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        return 'content-area-full'
      }
      return 'content-area-full'
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static',
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark'
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating',
      }
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    getNavMenuItems() {
      const role = this.activeUserInfo.account_role
      switch (role) {
        case 0:
          adminNav.reload()
          return adminNav.navMenuItems
        case 1:
          resellerNav.reload()
          return resellerNav.navMenuItems
        case 2:
          sellerNav.reload()
          return sellerNav.navMenuItems
        case 3:
          adminNav.reload()
          return adminNav.navMenuItems
        default:
          return []
      }
    },
  },
  methods: {
    getOtherContacts() {
      let home = JSON.parse(localStorage.getItem("home"))
      if (home.contact_details.support_info) {
        let support_info = home.contact_details.support_info;
        support_info = support_info.replaceAll("</a> ", "</a><div class='pr-2'></div>")
        support_info = support_info.replaceAll("<br /><br />", "<br/><br/>");
        support_info = support_info.split("<br/><br/>");

        support_info.splice(0, 1);

        for (let i = 0; i < support_info.length; i++) {
          let names = support_info[i].split("<div class='pr-2'></div>")
          support_info[i] = [names[1], names[0]]
        }
        return support_info;
        return `<div class='pr-4'></div>${support_info.join("<div class='pr-4'></div>")}`;
      } else {
        return "";
      }
    },
    updateUser() {
      this.navItems = this.getNavMenuItems;
      this.componentKey += 1;
    },
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbar(val) {
      if (val === 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff')
      this.navbarType = val
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter(val) {
      this.footerType = val
    },
    updateRouterTransition(val) {
      this.routerTransition = val
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val
    },

    getHomeData() {
      try {
        this.homeData = JSON.parse(localStorage.getItem('home'))
      } catch (e) {
        return null
      }
    },
  },
  mounted() {
    this.updateUser()
  },
  created() {
    this.getHomeData()
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true
        this.dynamicWatchers.windowWidth()
      }
    })

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.verticalNavMenuWidth()
    })

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.rtl()
    })
    this.updateUser()
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]()
      delete this.dynamicWatchers[i]
    })
  },
}

</script>

