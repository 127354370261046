var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex flex-row justify-between px-9 py-1 text-xl"},[_c('div',{staticClass:"flex flex-row pr-4 text-xl"},[_c('p',{},[_c('strong',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t('Welcome')))]),_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.activeUserInfo.organisation_name))]),_c('span',{staticClass:"text-lg"},[_vm._v("("),_c('small',[_vm._v(_vm._s(_vm.activeUserInfo.customer_id))]),_vm._v(")")])])]),_c('div',{staticClass:"flex flex-row text-lg"},[_c('i18n')],1)]),_c('div',{staticClass:"vx-navbar-wrapper",class:_vm.classObj},[_c('vs-navbar',{staticClass:"vx-navbar navbar-custom navbar-skelton",class:_vm.textColor,attrs:{"color":_vm.navbarColorLocal}},[_c('feather-icon',{staticClass:"sm:inline-flex xl:hidden cursor-pointer p-2",attrs:{"icon":"MenuIcon"},on:{"click":function($event){$event.stopPropagation();return _vm.showSidebar($event)}}}),(_vm.hasCreditAndBalances)?[_c('div',{staticClass:"p-2"}),_c('div',{staticClass:"flex flex-col sm:flex-row"},[_c('div',{staticClass:"pr-3 border-0 md:border-r border-solid border-grey-light",class:[
               {
                 'text-warning': _vm.activeUserInfo.template != 5,
                 'text-black': _vm.activeUserInfo.template == 5
               }
             ],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('Credit')))]),_c('strong',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.credit)))])])]),(_vm.activeUserInfo.template == 5)?_c('div',{staticClass:"pl-3 pr-3 border-0 md:border-r border-solid border-grey-light text-black",staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('Balance')))]),_c('strong',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type])))])])]):_vm._e(),(_vm.activeUserInfo.template != 5)?_c('div',{staticClass:"pl-3 pr-3 border-0 md:border-r border-solid border-grey-light",class:[_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type] < 0 ? 'text-danger' : 'text-success'],staticStyle:{"text-align":"end","white-space":"nowrap"}},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('Balance')))]),_c('strong',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.balance[_vm.activeUserInfo.balance_type])))])])]):_vm._e()]),_c('div',{staticClass:"flex flex-col sm:flex-row"},[(_vm.activeUserInfo.account_role >= 2)?_c('div',{staticClass:"pl-3 pr-3 border-0 md:border-r border-solid border-grey-light today-sale-data",class:[
                 {
                   'text-warning': _vm.activeUserInfo.template != 5,
                   'text-black': _vm.activeUserInfo.template == 5
                 }
               ]},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('Sales')))]),_c('strong',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm._f("germanNumberFormat")(_vm.activeUserInfo.today_balance)))])])]):_vm._e(),(_vm.activeUserInfo.account_role >= 2)?_c('router-link',{staticClass:"cursor-pointer",class:[
                         {
                           'text-primary': _vm.activeUserInfo.template != 5,
                           'text-black': _vm.activeUserInfo.template == 5
                         }
                       ],attrs:{"tag":"div","to":"/seller/sales"}},[_c('div',{staticClass:"pl-3 pr-3"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('SalesCount')))]),_c('strong',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.activeUserInfo.today_sale_count))])])])]):_vm._e()],1)]:_vm._e(),_c('vs-spacer'),_c('div',{staticClass:"hidden md:block"},[_c('i18n')],1),_c('div',{staticClass:"p-2"}),_c('profile-drop-down')],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }